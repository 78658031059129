<template>
	<div>
		<CCard class="p-4">
			<CRow>

				<CCol lg="6">

					<h5>Vehicles ({{ getCount }})</h5>
				</CCol>
				<CCol lg="6">
					<CButton size="sm" style="float: right;"
						:class="'float-lg-right block ' + showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse1"
						@click="showCollapse = !showCollapse" color="info"><i class="fa fa-search"></i> Filter<i
							:class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
					<CButton v-if="config.getPermission('vehicle').create" size="sm" style="margin-right: 10px"
						color="success" href="#/data/create_vehicle" class="float-lg-right">
						<i class="fa fa-plus">
						</i>Add Vehicle
					</CButton>


				</CCol>
			</CRow>
			<CRow>
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
						<CCard class="p-4">
							<Search @depot-search-query="search" />
						</CCard>
					</CCollapse>
				</CCol>
			</CRow>
			<hr>


			<CRow>

				<CCol lg="12">
					<div class="table-responsive-sm table-responsive-md">
						<CTabs variant="pills" :active-tab="0">
							<CTab title="All" @click.native="onChangeTab('all')">
								<br />

								<CDataTable :loading="isLoading" :items="dataList.data"
									:fields="company_type == 'trucking' ? fields_2 : fields" hover striped outlined>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="config.getPermission('vehicle').view"
													@click="viewModal(item)"><i class="fa fa-eye"> View </i></CDropdownItem>
												<CDropdownItem v-if="config.getPermission('vehicle').update"
													@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit </i>
												</CDropdownItem>
												<!-- <CDropdownItem @click="showOdoModal(item)"><i class="fa fa-cogs"> Update Odometer </i></CDropdownItem>  -->
												<CDropdownItem
													v-if="config.getPermission('vehicle').update && !is_trucking()"
													@click="showTransferModal(item)"><i class="fa fa-download"> Transfer
														Vehicle </i></CDropdownItem>
												<CDropdownItem v-if="!is_trucking()"
													@click="showTransferHistoryModal(item)"><i class="fa fa-clock-o">
														Transfer History </i></CDropdownItem>
												<!-- <CDropdownItem @click="viewModal(item)">Assign</CDropdownItem>  -->
												<!-- <CDropdownItem @click="showOdoModal(item)"><i class="fa fa-cogs"> Update Odometer </i></CDropdownItem>  -->
												<CDropdownItem v-if="config.getPermission('vehicle').delete"
													@click="deleteRow(item)"><i class="fa fa-trash"> Delete </i>
												</CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPage" :pages="Math.ceil(dataList.total / 20)"
									:activePage="currentPage" @update:activePage="updatePage" />
							</CTab>
							<CTab title="Active" @click.native="onChangeTab('active')">
								<br />

								<CDataTable :loading="isLoading" :items="dataListActive.data"
									:fields="company_type == 'trucking' ? fields_2 : fields" hover striped outlined>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="config.getPermission('vehicle').view"
													@click="viewModal(item)"><i class="fa fa-eye"> View </i></CDropdownItem>
												<CDropdownItem v-if="config.getPermission('vehicle').update"
													@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit </i>
												</CDropdownItem>
												<CDropdownItem
													v-if="config.getPermission('vehicle').update && !is_trucking()"
													@click="showTransferModal(item)"><i class="fa fa-download"> Transfer
														Vehicle </i></CDropdownItem>
												<CDropdownItem v-if="!is_trucking()"
													@click="showTransferHistoryModal(item)"><i class="fa fa-clock-o">
														Transfer History </i></CDropdownItem>
												<!-- <CDropdownItem @click="viewModal(item)">Assign</CDropdownItem>  -->
												<!-- <CDropdownItem @click="showOdoModal(item)"><i class="fa fa-cogs"> Update Odometer </i></CDropdownItem>  -->
												<CDropdownItem v-if="config.getPermission('vehicle').delete"
													@click="deleteRow(item)"><i class="fa fa-trash"> Delete </i>
												</CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageActive"
									:pages="Math.ceil(dataListActive.total / 20)" :activePage="currentPageActive"
									@update:activePage="updatePageActive" />
							</CTab>
							<CTab title="Inactive" @click.native="onChangeTab('inactive')">
								<br />

								<CDataTable :loading="isLoading" :items="dataListInactive.data"
									:fields="company_type == 'trucking' ? fields_2 : fields" hover striped outlined>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="config.getPermission('vehicle').view"
													@click="viewModal(item)"><i class="fa fa-eye"> View </i></CDropdownItem>
												<CDropdownItem v-if="config.getPermission('vehicle').update"
													@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit </i>
												</CDropdownItem>
												<CDropdownItem
													v-if="config.getPermission('vehicle').update && !is_trucking()"
													@click="showTransferModal(item)"><i class="fa fa-download"> Transfer
														Vehicle </i></CDropdownItem>
												<CDropdownItem v-if="!is_trucking()"
													@click="showTransferHistoryModal(item)"><i class="fa fa-clock-o">
														Transfer History </i></CDropdownItem>
												<!-- <CDropdownItem @click="viewModal(item)">Assign</CDropdownItem>  -->
												<!-- <CDropdownItem @click="showOdoModal(item)"><i class="fa fa-cogs"> Update Odometer </i></CDropdownItem>  -->
												<CDropdownItem v-if="config.getPermission('vehicle').delete"
													@click="deleteRow(item)"><i class="fa fa-trash"> Delete </i>
												</CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageInactive"
									:pages="Math.ceil(dataListInactive.total / 20)" :activePage="currentPageInactive"
									@update:activePage="updatePageInactive" />
							</CTab>
						</CTabs>
					</div>
				</CCol>
			</CRow>

			<!-- <OdometerModal :details="odoParams" @showModal="odoModal = $event; reload();" :showModal="odoModal" />  -->
			<CModal title="Transfer Vehicle" :show.sync="formModal" @close="closeModal()" color="info" size="lg">

				<form @submit.prevent="submit">
					<div class="pl-2 pr-2 pb-2">
						<CRow>
							<CCol lg="6">
								<small>Plate No.</small>
								<CInput disabled type="text" class="mb-2" v-model="transferParam.plate_no" />
							</CCol>
							<CCol lg="6">
								<small>Asset No.</small>
								<CInput disabled type="text" class="mb-2" v-model="transferParam.asset_no" />
							</CCol>
							<CCol lg="12">
								<small>Current Location</small>
								<CInput disabled type="text" class="mb-2" v-model="transferParam.current_location_name" />
							</CCol>

						</CRow>
						<hr>

						<CRow>
							<CCol lg="12">
								<small>Transfer Date <span class="text-danger">*</span></small>
								<Datepicker input-class="form-control bg-white" v-model="transferParam.transfer_date"
									required>
								</Datepicker>
								<!-- <Datepicker input-class="form-control"  class="mb-2" format="MM-DD-YYYY" required v-model="transferParam.transfer_date" /> -->
							</CCol>
						</CRow>
						<CRow class="pt-3">
							<CCol lg="12">
								<div class="form-group">
									<small>Origin <span class="text-danger">*</span></small>
									<v-select label="setting_name" :options="originList.data" :reduce="item => item.id"
										v-model="transferParam.origin_id" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!transferParam.origin_id"
												v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<div class="form-group">
									<small>Transfer to <span class="text-danger">*</span></small>
									<v-select label="customer_name" :options="customerList.data"
										v-model="transferParam.transfer_to" placeholder="-Select-">
										<template #search="{ attributes, events }">
											<input class="vs__search" :required="!transferParam.transfer_to"
												v-bind="attributes" v-on="events" />
										</template>
									</v-select>
								</div>
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<small class="mr-2">Depot</small>
								<CInput disabled
									:value="(transferParam.transfer_to ? transferParam.transfer_to.setting_name : '')"
									type="text" class="mb-2" />
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<small>MTS No.</small>
								<CInput type="text" class="mb-2" v-model="transferParam.mts_no" />
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="12">
								<small>Remarks</small>
								<textarea class="form-control mb-2" v-model="transferParam.remarks"></textarea>
							</CCol>
						</CRow>

					</div>
					<div slot="footer" class="w-100 mt-3">

						<CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info"
							class="ml-1 mr-1 float-right">
							Save
						</CButton>
						<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger"
							class="ml-1 mr-1 float-right" @click="formModal = false">
							Cancel
						</CButton>

					</div>
				</form>
				<div slot="footer" class="w-100"></div>

			</CModal>
			<CModal title="Transfer Vehicle History" :show.sync="historyModal" color="info" size="xl">

				<CRow>
					<CCol>
						<CRow>
							<CCol lg="6">
								<small>Plate No.</small>
								<CInput disabled type="text" class="mb-2" v-model="transferParam.plate_no" />
							</CCol>
							<CCol lg="6">
								<small>Asset No.</small>
								<CInput disabled type="text" class="mb-2" v-model="transferParam.asset_no" />
							</CCol>
							<CCol lg="12">
								<small>Current Location</small>
								<CInput disabled type="text" class="mb-2" v-model="transferParam.current_location_name" />
							</CCol>

						</CRow>
						<hr>

						<CDataTable :items="vehicleHistoryList.data" :fields="[
							{
								key: 'transfer_date',
								label: 'Transfer Date'
							},
							{
								key: 'origin_id_label',
								label: 'Origin'
							},
							{
								key: 'current_location_id_label',
								label: 'Transfer To'
							},
							{
								key: 'mts_no',
								label: 'MTS No.'
							},
							{
								key: 'remarks',
								label: 'Remarks'
							},
							{
								key: 'updated_by',
								label: 'Updated By'
							}
						]" hover outlined small fixed>
						</CDataTable>

					</CCol>
				</CRow>
				<div slot="footer" class="w-100">
					<!-- <CButton style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="closeTransfer">
					Close
				</CButton>   -->
				</div>
			</CModal>

			<!-- <OdometerModal :details="odoParams" @showModal="odoModal = $event; reload();" :showModal="odoModal" />  -->
			<!-- <UpdateOdoModal :details="selectedRow" @showModal="updateOdoModal = $event; reload();" :showModal="updateOdoModal" />  -->
		</CCard>

	</div>
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
// import OdometerModal from './odometer_modal'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import moment from 'moment';
import 'vue-select/dist/vue-select.css';
import UpdateOdoModal from './update_odometer';
import '../../style.css';

export default {

	mounted() {
		this.getData();
		this.getData('active');
		this.getData('inactive');
		// this.getDataInactive();
		// this.getDataActive();
		// this.getVendor();
		// this.getDepot();
		this.getOrigin();
		this.getCurrentLocation();
	},
	components: { Datepicker },
	data() {
		return {
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			config,
			historyModal: false,
			isLoading: false,
			formModal: false,
			// updateOdoModal: false,
			count: 0,
			transferParam: {
				vehicle_id: "",
				current_location_id: "",
				current_location_name: "",
				asset_no: "",
				remarks: "",
				transfer_date: "",
				transfer_to: "",
				origin_id: "",
			},
			showViewModal: false,
			dataList: [],
			dataListActive: [],
			dataListInactive: [],
			depotName: "",
			// odoModal: false, 
			fields: [
				{
					key: 'plate_no',
					label: 'Plate No.'
				},
				{
					key: 'asset_no',
					label: 'Asset No.'
				},

				// {
				// 	key: 'body_no', 
				// 	label: 'Body No.'
				// },

				{
					key: 'vehicle_type_id_label',
					label: ' Vehicle Type'
				},
				{
					key: 'maker_model_id_label',
					label: 'Maker'
				},
				
				{
					key: 'origin_id_label', 
					label: 'Origin'
				},
				{
					key: 'current_location',
					label: ' Current Location '
				},
				{
					key: 'depot_id_label',
					label: ' Depot '
				},


				// {
				// 	key: 'model_id_label', 
				// 	label: 'Model'
				// },
				// s
				// {
				// 	key: 'with_gps_label', 
				// 	label: 'With GPS'
				// },
				{
					key: 'company_owned_label',
					label: 'Company Owned'
				},
				{
					key: 'vendor_label',
					label: 'Subcon'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px' }
				}
			],
			fields_2: [
				{
					key: 'plate_no',
					label: 'Plate No.'
				},


				{
					key: 'vehicle_type_id_label',
					label: ' Vehicle Type'
				},
				{
					key: 'depot_id_label',
					label: ' Depot '
				},

				{
					key: 'maker_model_id_label',
					label: 'Maker'
				},
				// {
				// 	key: 'model_id_label', 
				// 	label: 'Model'
				// },
				// s
				{
					key: 'with_gps_label', 
					label: 'With GPS'
				},
				{
					key: 'company_owned_label',
					label: 'Company Owned'
				},
				{
					key: 'vendor_label',
					label: 'Subcon'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px' }
				}
			],
			user_data: JSON.parse(window.localStorage.getItem('user_data')),
			currentPage: 1,
			currentPageActive: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				plate_no: "",
				body_no: "",
				asset_no: "",
				maker_model_id: "",
				vehicle_type_id: "",
				year: "",
				depot_id: null,
				company_owned: "",
				vendor_id: ""
			},
			vendorList: {
				data: []
			},
			depotList: {
				data: []
			},
			customerList: {
				data: []
			},
			originList: {
				data: [],
			},
			vehicleHistoryList: {
				data: []
			},
			config,
			odoParams: {
				vehicle_type_id: "",
				maker_model_id: "",
				origin_id: "",
				vendor_id: "",
				driver_id: "",
				body_no: "",
				year: "",
				with_gps: "no",
				company_owned: true,
				plate_no: "",
				registration_date: "",
				asset_no: "",
				engine_no: "",
				engine_size: "",
				chassis_no: "",
				transmission_type: "",
				transfer_date: "",
				color: "",
				fuel_type: "",
				later_renewal_date: "",
				acquisition_date: "",
				acquisition_price: "",
				remarks: "",
				vehicle_photo: "",
				or_photo: "",
				cr_photo: "",
				insurance_photo: "",
				status: "active",
				location: "",
				breakdown_date: "",
				project_id: ""
			},


		}
	},
	name: 'Tables',
	components: { Search, Datepicker, vSelect },
	computed: {
		is_valid() {
			//return this.transferParam.transfer_to && this.transferParam.transfer_date
		},
		getCount() {
			return this.count;
		}
	},
	methods: {
		onChangeTab(tab) {
			switch (tab) {
				case 'all':
					this.count = this.dataList.total;
					break;
				case 'active':
					this.count = this.dataListActive.total;
					break;
				case 'inactive':
					this.count = this.dataListInactive.total;
					break;
			}
		},
		getBadge(status) {
			status = status.toLowerCase();
			return status === 'active' ? 'success'
				: status === 'inactive' ? 'danger' : 'primary'
		},
		showTransferHistoryModal(item) {

			this.getVehicleHistory(item);
			this.historyModal = true;
			this.transferParam.asset_no = item?.asset_no ?? '';
			this.transferParam.plate_no = item?.plate_no ?? '';
			this.transferParam.transfer_to = this.customerList.data.find(d => d.id == item.current_location_id) ?? {};
			if (item.current_location_id == null) {
				this.transferParam.current_location_name = '';
			} else {
				this.transferParam.current_location_name = item.current_location;
			}

		},
		closeTransfer() {
			this.historyModal = false;
		},
		transferToChanged(item) {
			this.transferParam.transfer_to = item;
			this.transferParam.transfer_to = this.customerList.data.filter(x => x.id == item.origin_id)[0];
		},
		showTransferModal(item) {

			this.formModal = true;
			this.transferParam.transfer_to = this.customerList.data.find(d => d.id == item.current_location_id) ?? {};
			this.transferParam.origin_id = this.originList.data.find(d => d.id == item.origin_id) ?? {};
			this.transferParam.asset_no = item?.asset_no ?? '';
			this.transferParam.plate_no = item?.plate_no ?? '';
			// this.transferParam.origin_id = item?.origin_id ?? '';
			this.transferParam.vehicle_id = item.id;
			this.transferParam.remarks = item.remarks;
			this.transferParam.mts_no = item.mts_no;
			this.transferParam.transfer_date = item.transfer_date;
			if (item.origin_id == null) {
				this.transferParam.origin_id = '';
			} else {
				this.transferParam.origin_id = item?.origin_id ?? '';
			}
			if (item.current_location_id == null) {
				this.transferParam.current_location_name = '';
			} else {
				this.transferParam.current_location_name = item.current_location;
			}
		},
		reload() {
			setTimeout(() => {
				this.getData();
			}, 500)
		},
		updatePage(data) {
			this.currentPage = data;
			this.getData();
		},
		reload() {
			this.getData();
			this.getData('active');
			this.getData('inactive');
			// this.getDataInactive();
			// this.getDataActive();
		},
		updatePageActive(data) {
			// console.log(data, 'IIIII')
			this.currentPageActive = data;
			this.getData('active');
		},

		updatePageInactive(data) {
			this.currentPageInactive = data;
			this.getData('inactive');
		},

		toggleModal() {
			this.formModal = !this.formModal;
		},
		//  UpdateOdoModal(item){
		// 	this.updateOdoModal = true;
		// 	this.selectedRow = item;
		// },



		search(event) {
			this.filter = event;
			this.getData();
			this.getData('active');
			this.getData('inactive');
		},


		getData(type = '') {
			this.isLoading = true;
			let maker_model_id = this.filter.maker_model_id;
			if (maker_model_id == "all" || maker_model_id == null) {
				maker_model_id = ""
			}
			let vehicle_type_id = this.filter.vehicle_type_id;
			if (vehicle_type_id == "all" || vehicle_type_id == null) {
				vehicle_type_id = ""
			}
			let project_id = this.filter.project_id;
			if (project_id == "all" || project_id == null) {
				project_id = ""
			}
			let location = this.filter.location;
			if (location == "all" || location == null) {
				location = ""
			}

			let vendor_id = this.filter.vendor_id;
			if (vendor_id == "all" || vendor_id == null) {
				vendor_id = ""
			}
			let current_page = this.currentPage;
			switch (type) {
				case 'active':
					current_page = this.currentPageActive;
					break;
				case 'inactive':
					current_page = this.currentPageInactive;
			}

			let status = type;

			axios.get(`${config.api_path}/vehicle`, {
				params: {
					plate_no: this.filter.plate_no,
					body_no: this.filter.body_no,
					asset_no: this.filter.asset_no,
					year: this.filter.year,
					maker_model_id,
					vehicle_type_id,
					location,
					project_id,
					page: current_page,
					limit: 20,
					vendor_id,
					company_owned: this.filter.company_owned,
					status
				}
			})
				// axios.get(config.api_path+'/vehicle?plate_no='
				// 	+this.filter.plate_no+'&body_no='+this.filter.body_no+'&year='
				// 	+this.filter.year+'&maker_model_id='+maker_model_id+'&vehicle_type_id='+vehicle_type_id+'&location='+location+'&project_id='+project_id+'&page='+this.currentPage+'&limit=20')
				// .
				.then(response => {
					let data = response.data;
					data.data = data.data.map((value, index) => {
						value.company_owned_label = "N/A";
						if (value.company_owned == "1" || value.company_owned == 1) {
							value.company_owned_label = "Yes";
						}
						else {
							value.company_owned_label = "No";
						}

						if (value.vehicle_type_id) {
							value.vehicle_type_id_label = value.vehicle_type.setting_name
						}

						value.maker_model_id_label = value?.maker_model?.setting_name ?? '';

						value.current_location = value?.current_location?.customer_name ?? '';
						value.asset_no = value?.asset_no ?? '';
						// if(value.maker_model_id){
						// 	value.maker_model_id_label = value.maker_model.setting_name
						// }
						if (value.with_gps) {
							value.with_gps_label = "Yes";
						}
						else {
							value.with_gps_label = "No";
						}
						if (value.location_label == null) {
							value.depot_id_label = " ";
						}
						else {
							value.depot_id_label = value.location_label.setting_name;
						}
						// value.location_id_label = "N/A";
						// if(value.location_label){
						// 	value.location_id_label = value.location_label.setting_name
						// }

						value.body_no = value.body_no ?? '';
						value.origin_id_label = value?.origin?.setting_name ?? '';

						if (value.transfer_date != null) {

							value.transfer_date_label = value.transfer_date
						}
						else {
							value.transfer_date_label = "N/A"
						}


						value.vendor_label = " "
						if (value.vendor) {
							value.vendor_label = value.vendor.vendor_name;
						}



						value.project_label = "N/A";
						if (value.project) {
							value.project_label = value.project.customer_name
						}

						return value;
					});

					switch (type) {
						case 'active':
							this.dataListActive = { ...data };
							break;
						case 'inactive':
							this.dataListInactive = { ...data };
							break;
						default:
							this.dataList = { ...data }
							this.count = this.dataList.total
							break;
					}

				})
				.catch(err => {
					console.error(err)
					this.isLoading = false;
				})
				.finally(fin => {
					console.log(fin)
					this.isLoading = false;
				})
		},

		// getDataActive(){
		// 	this.isLoading = true;
		// 	var maker_model_id = this.filter.maker_model_id;
		// 	if(maker_model_id == "all" || maker_model_id == null){
		// 		maker_model_id = ""
		// 	}
		// 	var vehicle_type_id = this.filter.vehicle_type_id;
		// 	if(vehicle_type_id == "All" || vehicle_type_id == null){
		// 		vehicle_type_id = ""
		// 	} 

		// 	axios.get(config.api_path+'/vehicle?depot_id='+ this.filter.depot_id +'&status=active&plate_no='+this.filter.plate_no+'&body_no='+this.filter.body_no+'&year='+this.filter.year+'&maker_model_id='+maker_model_id+'&vehicle_type_id='+vehicle_type_id+'&page='+this.currentPage+'&limit=20')
		// 	.then(response => {
		// 		this.dataListActive = response.data; 
		// 		this.dataListActive.data = this.dataListActive.data.map((value, index)=>{

		// 			value.company_owned_label = "N/A";
		// 			if(value.company_owned == "1" || value.company_owned == 1){
		// 				value.company_owned_label = "Yes";
		// 			}
		// 			else{
		// 				value.company_owned_label = "No";
		// 			}

		// 			value.body_no = value.body_no ?? 'N/A';

		// 			if(value.vehicle_type_id){
		// 				value.vehicle_type_id_label = value.vehicle_type.setting_name
		// 			}
		// 			if(value.location_label == null){
		// 				value.depot_id_label = "N/A";
		// 			}
		// 			else{
		// 				value.depot_id_label = value.location_label.setting_name;
		// 			}
		// 			if(value.maker_model_id == null){
		// 				value.maker_model_id_label = "N/A";
		// 			}
		// 			else{
		// 				value.maker_model_id_label = value.maker_model.setting_name;
		// 			}
		// 				value.model_id_label = value?.model?.setting_name ?? 'N/A';
		// 			// if(value.maker_model_id){
		// 			// 	value.maker_model_id_label = value.maker_model.setting_name
		// 			// }
		// 			if(value.plate_no == "" || value.plate_no== null){
		// 				value.plate_no = "N/A";
		// 			}
		// 			if(value.asset_no != ""){
		// 				value.asset_no = value.asset_no
		// 			}
		// 			else{
		// 				value.asset_no= "N/A"
		// 			}
		// 			value.asset_no_label = "N/A";
		// 			if(value.asset_no){
		// 				value.asset_no_label = value.asset_no
		// 			}
		// 			if(value.plate_no == "" || value.plate_no== null){
		// 				value.plate_no = "N/A";
		// 			}

		// 			value.vendor_label = "N/A"
		// 			if(value.vendor){
		// 				value.vendor_label = value.vendor.vendor_name;
		// 			}

		// 			if(value.with_gps){
		// 				value.with_gps_label = "Yes";
		// 			}
		// 			else{
		// 				value.with_gps_label = "No";
		// 			}
		// 			return value;
		// 		}); 
		// 	})
		// 	.catch(err => {
		// 		console.error(err)
		// 		this.isLoading = false;
		// 	})
		// 	.finally(fin => {
		// 		console.log(fin)
		// 		this.isLoading = false;	
		// 	})
		// },


		// getDataInactive(){
		// this.isLoading = true;
		// 	var maker_model_id = this.filter.maker_model_id;
		// 	if(maker_model_id == "all" || maker_model_id == null){
		// 		maker_model_id = ""
		// 	}
		// 	var vehicle_type_id = this.filter.vehicle_type_id;
		// 	if(vehicle_type_id == "All" || vehicle_type_id == null){
		// 		vehicle_type_id = ""
		// 	} 

		// 	axios.get(config.api_path+'/vehicle?depot_id='+ this.filter.depot_id +'&status=inactive&plate_no='+this.filter.plate_no+'&body_no='+this.filter.body_no+'&year='+this.filter.year+'&maker_model_id='+maker_model_id+'&vehicle_type_id='+vehicle_type_id+'&page='+this.currentPage+'&limit=20')
		// 	.then(response => {
		// 		this.dataListInactive = response.data; 
		// 		this.dataListInactive.data = this.dataListInactive.data.map((value, index)=>{

		// 			value.company_owned_label = "N/A";
		// 			if(value.company_owned == "1" || value.company_owned == 1){
		// 				value.company_owned_label = "Yes";
		// 			}
		// 			else{
		// 				value.company_owned_label = "No";
		// 			}
		// 			if(value.location_label == null){
		// 				value.depot_id_label = "N/A";
		// 			}
		// 			else{
		// 				value.depot_id_label = value.location_label.setting_name;
		// 			}
		// 			if(value.vehicle_type_id){
		// 				value.vehicle_type_id_label = value.vehicle_type.setting_name
		// 			}
		// 			if(value.maker_model_id == null){
		// 				value.maker_model_id_label = "N/A";
		// 			}
		// 			else{
		// 				value.maker_model_id_label = value.maker_model.setting_name;
		// 			}
		// 				value.model_id_label = value?.model?.setting_name ?? 'N/A';
		// 			if(value.plate_no == "" || value.plate_no== null){
		// 				value.plate_no = "N/A";
		// 			}
		// 			if(value.asset_no != ""){
		// 				value.asset_no = value.asset_no
		// 			}
		// 			else{
		// 				value.asset_no= "N/A"
		// 			}

		// 			value.body_no = value.body_no ?? 'N/A';

		// 			value.vendor_label = "N/A"
		// 			if(value.vendor){
		// 				value.vendor_label = value.vendor.vendor_name;
		// 			}
		// 			value.asset_no_label = "N/A";
		// 			if(value.asset_no){
		// 				value.asset_no_label = value.asset_no
		// 			}
		// 			// if(value.maker_model_id){
		// 			// 	value.maker_model_id_label = value.maker_model.setting_name
		// 			// }
		// 			if(value.plate_no == "" || value.plate_no== null){
		// 				value.plate_no = "N/A";
		// 			}

		// 			if(value.with_gps){
		// 				value.with_gps_label = "Yes";
		// 			}
		// 			else{
		// 				value.with_gps_label = "No";
		// 			}
		// 			return value;
		// 		}); 
		// 	})
		// 	.catch(err => {
		// 		console.error(err)
		// 		this.isLoading = false;
		// 	})
		// 	.finally(fin => {
		// 		console.log(fin)
		// 		this.isLoading = false;	
		// 	})
		// },



		updateModal(item) {

			// this.selectedRow = item;
			// this.dataParams = item;
			// this.formModal = true;
			// this.editMode = true;
			// window.open('/#/main/update_booking/'+item.id, '_blank');
			window.open('/#/data/update_vehicle/' + item.id, '_blank');
			// this.$router.push('/data/update_vehicle/'+item.id)

		},

		viewModal(item) {

			// this.selectedRow = item;
			// this.dataParams = item;
			// this.showViewModal = true; 
			window.open('/#/data/view_vehicle/' + item.id, '_blank');
			// this.$router.push('/data/view_vehicle/'+item.id)

		},
		getCurrentLocation() {
			axios.get(config.api_path + '/reference/customer-list', {
				params: {
					show_all: true
				}
			})
				.then(response => {
					this.customerList = response.data;
				})
		},

		deleteRow(item) {
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to delete this vehicle?',
				showCancelButton: true,
				confirmButtonText: `Yes`,
			}).then((result) => {
				if (result.isConfirmed) {
					axios.delete(config.api_path + '/vehicle/' + item.id)
						.then(response => {
							this.getData();
							this.getDataInactive();
							this.getDataActive();
							Swal.fire({
								title: 'Success!',
								text: "Vehicle successfully deleted",
								icon: 'success',
							})
						})
				}
			})
		},

		

		
		getOrigin() {
			axios
				.get(config.api_path + "/reference/settings-list", {
					params: {
						setting_type: "origin",
					},
				})
				.then((response) => {
					this.originList = response.data;
				});
		},
		// getDepot() {

		// 	axios.get(config.api_path + '/setting?setting_type=depot&page=1&limit=10000000')
		// 		.then(response => {
		// 			this.depotList = response.data;
		// 			this.depotList.data = this.depotList.data.map((value, index) => {
		// 				value.value = value.id;
		// 				value.label = value.setting_name;
		// 				return value;
		// 			})
		// 		})

		// },

	
		// showOdoModal(data){

		// 	this.odoParams = data;
		// 	this.odoModal = true;

		getVehicleHistory(item) {
			axios.get(config.api_path + '/vehicleHistory/' + item.id)
				.then(response => {
					this.vehicleHistoryList = response.data;
					// console.log(this.vehicleHistoryList.data);
					this.vehicleHistoryList.data = this.vehicleHistoryList.data.map((value, index) => {
						value.transfer_date = moment(value.transfer_date).format('MM/DD/YYYY');
						value.mts_no = value?.mts_no ?? "";
						value.remarks = value?.remarks ?? "";
						// if (value.origin_id == null) {
						// 	value.origin_id = "";
						// } else {
						// 	value.origin_id = value?.origin.setting_name ?? "";
						// }
						value.origin_id_label = value?.origin?.setting_name ?? '';
						value.current_location_id_label = value?.current_location?.customer_name ?? '';
						
						value.current_location_id = value?.current_location.customer_name ?? "";
						// value.updated_by = value?.user.firstname ?? "";
						value.updated_by = value.user ? `${value.user.lastname}, ${value.user.firstname}` : '';
						return value;
					})

				})
		},
		submit() {
			this.$showLoading(true);
			const transferVehicleParamPayload = {
				transfer_date: moment(this.transferParam.transfer_date).format('YYYY/MM/DD'),
				transfer_to: this.transferParam.transfer_to.id,
				mts_no: this.transferParam.mts_no ? this.transferParam.mts_no : "",
				remarks: this.transferParam.remarks ? this.transferParam.remarks : "",
				origin_id: this.transferParam.origin_id,
				vehicle_id: this.transferParam.vehicle_id,
				updated_by: this.user_data.id,
				depot_id : this.transferParam?.transfer_to?.depot_id ?? null
			}
			axios.post(config.api_path + '/vehicleHistory', transferVehicleParamPayload)
			.then(res => {
				this.$showLoading(false)
				this.formModal = false;
				this.getData();
				this.transferParam.transfer_to = '';
				this.transferParam.mts_no = '';
				this.transferParam.remarks = '';
				Swal.fire({
					title: 'Success!',
					text: "Vehicle successfully transferred",
					icon: 'success',
				})
			})
			.catch(err => {
				this.$showLoading(false)
			})
	}
	}
}

</script>
